
import { Vue, Component } from "vue-property-decorator";
@Component({
    metaInfo: {
    meta: [
    { charset: 'UTF-8' },
    ],
  },
})
export default class TermsAndConditions extends Vue {
    provider = ""
    adress = ""
    taxId = ""
    mounted() {
      this.provider = this.$route.query.provider as string
      this.adress = this.$route.query.adress as string
      this.taxId = this.$route.query.taxid as string
    }
}
